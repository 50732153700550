import React, { useState, useEffect } from "react";
import { Card, Space, Upload } from "lib";
import { Button, message, Modal } from "antd";
import { Layout } from "layout";
import {
  ReportTemplateNormTablesClient,
  ReportTemplateBriefDto,
  NormTablesClient,
  ReportTemplatesClient,
  UpdateReportTemplateCommand,
  ReportGenerationMethod,
} from "@api";
import { Download, showError } from "@action";
import { FormattedMessage } from "react-intl";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
// import ReportsMenu from "@components/Admin/Reports/ReportsMenu";
import { RcFile } from "antd/lib/upload";
import { navigate } from "gatsby";
import GeneralFormModal from "@components/GeneralFormModal";
import DescriptionContent from "@components/Admin/Reports/DescriptionContent";
import NormTableContent from "@components/Admin/Reports/NormTableContent";
import { DefaultOptionType } from "antd/lib/select";

interface Params {
  id: number;
}
interface Props {
  params: Params;
}

const Page = ({ params }: Props) => {
  const auth = new SubpageAuth(
    SECTION.Admin,
    "Reports",
    "ReportTemplatesContents"
  );
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const templateId = params.id;

  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [updateModalVisible, setUpdateModalVisible] = useState(false);

  const allLanguageFetch = {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
      const _init = {
        ...init,
        headers: {
          ...init?.headers,
          "accept-language": "*",
        },
      };
      return fetch(url, _init);
    },
  };
  const reportTemplateNormTablesClient = new ReportTemplateNormTablesClient(
    undefined,
    allLanguageFetch
  );
  const normTablesClient = new NormTablesClient(undefined, allLanguageFetch);
  const reportTemplatesClient = new ReportTemplatesClient();

  const [reportTemplateBriefDto, setReportTemplateBriefDto] = useState(
    undefined as ReportTemplateBriefDto | undefined
  );

  async function fetchReportTemplateBriefDto() {
    if (auth.cannot(ACTION.List, "ReportTemplatesContents")) return;

    const hide = messageApi.loading(
      <FormattedMessage id="StaticLoadingMessage" />,
      0
    );
    try {
      const resReportTemplate = await reportTemplatesClient.get(templateId);
      messageApi.success(<FormattedMessage id="StaticLoadSuccessMessage" />);
      setReportTemplateBriefDto(resReportTemplate);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadTemplate(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );

    try {
      await reportTemplatesClient.uploadTemplate(templateId, {
        data: f,
        fileName: f.name,
      });
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      fetchReportTemplateBriefDto();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadTemplate() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );

    try {
      const res = await reportTemplatesClient.getTemplate(templateId);
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadConfiguration(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );

    try {
      await reportTemplatesClient.uploadReportTemplateConditions(templateId, {
        data: f,
        fileName: f.name,
      });
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      fetchReportTemplateBriefDto();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadConfiguration() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );

    try {
      const res = await reportTemplatesClient.exportReportTemplateConditions(
        templateId
      );
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadCombineTokenRule(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );

    try {
      await reportTemplatesClient.uploadReportTemplateCombineTokenRules(templateId, {
        data: f,
        fileName: f.name,
      });
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      fetchReportTemplateBriefDto();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadCombineTokenRule() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );

    try {
      const res = await reportTemplatesClient.exportReportTemplateCombineTokenRules(
        templateId
      );
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  const handleUpdateContent = async (data: any) => {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUpdatingMessage" />
    );
    try {
      await reportTemplatesClient.update(
        templateId,
        new UpdateReportTemplateCommand({
          ...data,
          id: templateId,
        })
      );
      messageApi.success(<FormattedMessage id="StaticUpdateSuccessMessage" />);
      setUpdateModalVisible(false);
      await fetchReportTemplateBriefDto();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  };

  function handleCancelUpdateContent() {
    setUpdateModalVisible(false);
  }

  useEffect(() => {
    fetchReportTemplateBriefDto();
  }, []);

  return (
    <Layout title="ReportReportTemplateNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <br />
          <Space justify="space-between">
            <DescriptionContent briefDto={reportTemplateBriefDto}>
              {auth.can(ACTION.Edit, "ReportTemplatesContents") ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setUpdateModalVisible(true);
                  }}
                >
                  <FormattedMessage id="ReportReportTemplateContentEditButton" />
                </Button>
              ) : null}
            </DescriptionContent>
          </Space>
          <Space wrap justify="flex-end">
            {auth.can(ACTION.Download, "ReportTemplates") ? (
              <Button type="primary" onClick={() => handleDownloadTemplate()}>
                <FormattedMessage id="ReportReportTemplateContentDownloadButton" />
              </Button>
            ) : null}
            {auth.can(ACTION.Upload, "ReportTemplates") ? (
              <Upload action={handleUploadTemplate}>
                <Button type="primary">
                  <FormattedMessage id="ReportReportTemplateContentUploadButton" />
                </Button>
              </Upload>
            ) : null}
          </Space>
          <Space wrap justify="flex-end">
            {auth.can(ACTION.Download, "Configurations") ? (
              <Button
                type="primary"
                onClick={() => handleDownloadConfiguration()}
              >
                <FormattedMessage id="ReportReportTemplateContentDownloadConfigButton" />
              </Button>
            ) : null}
            {auth.can(ACTION.Upload, "Configurations") ? (
              <Upload action={handleUploadConfiguration}>
                <Button type="primary">
                  <FormattedMessage id="ReportReportTemplateContentUploadConfigButton" />
                </Button>
              </Upload>
            ) : null}
            {auth.can(ACTION.Download, "Configurations") ? (
              <Button
                type="primary"
                onClick={() => handleDownloadCombineTokenRule()}
              >
                <FormattedMessage id="ReportReportTemplateContentDownloadCombineTokenRuleButton" />
              </Button>
            ) : null}
            {auth.can(ACTION.Upload, "Configurations") ? (
              <Upload action={handleUploadCombineTokenRule}>
                <Button type="primary">
                  <FormattedMessage id="ReportReportTemplateContentUploadCombineTokenRuleButton" />
                </Button>
              </Upload>
            ) : null}
          </Space>
          <br />
          <NormTableContent
            auth={auth}
            templateId={templateId}
            modal={modal}
            messageApi={messageApi}
            reportTemplateNormTablesClient={reportTemplateNormTablesClient}
            normTablesClient={normTablesClient}
          />
        </Space>
      </Card>
      <GeneralFormModal
        visible={updateModalVisible}
        handleAction={handleUpdateContent}
        handleCancelAction={handleCancelUpdateContent}
        titleId={"ReportReportTemplateModalEditTitle"}
        okTextId={"StaticUpdateButton"}
        editableFields={[
          {
            name: "name",
            labelId: "ReportReportTemplateStaticNameLabel",
            type: "string",
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="ReportReportTemplateStaticMissingNameRule" />
                ),
              },
            ],
          },
          {
            name: "displayName",
            labelId: "ReportReportTemplateStaticDisplayNameLabel",
            type: "string",
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="ReportReportTemplateStaticMissingDisplayNameRule" />
                ),
              },
            ],
          },
          {
            name: "preferredLanguageId",
            labelId: "ReportReportTemplateStaticLanguageLabel",
            type: "lang",
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="ReportReportTemplateStaticMissingLanguageRule" />
                ),
              },
            ],
          },
          {
            name: "reportGenerationMethod",
            labelId: "ReportReportTemplateStaticGenerationMethodLabel",
            type: "select",
            options: Object.keys(ReportGenerationMethod)
              .filter((a) => isNaN(Number(a)))
              .map((a): DefaultOptionType => {
                return {
                  label: a,
                  value:
                    ReportGenerationMethod[
                      a as keyof typeof ReportGenerationMethod
                    ],
                };
              }),
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: "showUnmatchToken",
            labelId: "ReportGroupReportTemplateStaticShowUnmatchTokenLabel",
            type: "bool",
            rules: [],
          },
        ]}
        editObject={reportTemplateBriefDto}
        clearFormFlag={false}
      />
      {messageContextHolder}
      {modalContextHolder}
    </Layout>
  );
};

export default Page;
